import { Component, Inject } from '@angular/core'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'

@Component({
  selector: 'app-change-bot-name',
  templateUrl: './change-bot-name.component.html',
  styleUrls: ['./change-bot-name.component.scss'],
})
export class ChangeBotNameComponent {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onCancel() {
    this.dialogRef.close()
  }
}
