import { Component, Inject } from '@angular/core'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'

@Component({
  selector: 'app-delete_or_resolve-dialog',
  templateUrl: './bot-delete_or_resolve-dialog.component.html',
  styleUrls: ['./bot-delete_or_resolve-dialog.component.scss'],
})
export class BotDeleteOrResolveDialogComponent {
  public title

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data
  }
}
