import { Component, OnInit, ViewChild } from '@angular/core'
import { HtButtonDSIntent } from 'shared/base/ht-button-ds/ht-button-ds.component'
import {
  HtModalComponent,
  HtModalOptions,
} from 'shared/base/ht-modal/ht-modal.component'
import { AnalyticsEvents } from 'src/app/constants/analytics'
import { FeedbackService } from 'src/app/services/feedback.service'
import { Analytics } from 'utils/analytics'

@Component({
  selector: 'request-licenses-modal',
  templateUrl: './request-licenses-modal.component.html',
})
export class RequestLicensesModalComponent implements OnInit {
  @ViewChild('modal') modal: HtModalComponent

  modalOptions: HtModalOptions = {
    title: { text: 'Increase number of licenses' },
    size: 'small',
    rightBtnArray: [
      {
        show: true,
        text: 'Cancel',
        intent: HtButtonDSIntent.SECONDARY,
        click: () => this.modal.onClose(),
      },
      {
        show: true,
        text: 'Send request',
        intent: HtButtonDSIntent.PRIMARY,
        click: () => this.onSubmit(),
      },
    ],
  }

  constructor(private feedbackService: FeedbackService) {}

  ngOnInit(): void {}

  onSubmit() {
    Analytics.event(AnalyticsEvents.LICENSES_REQUESTED)
    this.feedbackService.success(
      'Licenses requested. Soon our customer support team will contact you.'
    )
    this.modal.close()
  }

  open() {
    Analytics.event(AnalyticsEvents.REQUEST_LICENSES_MODAL_OPEN)
    this.modal.open()
  }
}
