import { Component, Inject } from '@angular/core'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'

@Component({
  selector: 'app-delete-bot-confirm',
  templateUrl: './delete-bot-confirm.component.html',
  styleUrls: ['./delete-bot-confirm.component.scss'],
})
export class DeleteBotConfirmComponent {
  public userInput: any
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  isValid() {
    return this.userInput === this.data
  }
}
