import { HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { environment } from 'environment/environment'
import { JsonConvert } from 'json2typescript'
import { EMPTY, Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import {
  HubtypeBot,
  HubtypeBotUpdate,
  HubtypeBotUpdatePaginator,
} from '../../models/hubtype-bot'
import { Paginator } from '../../models/paginator'
import * as fromRoot from '../../reducers'
import { ConverterService } from '../converter.service'
import { CustomQueryEncoderHelper } from './auth.service'
import { HubtypeApiService } from './hubtype-api.service'

interface CreateAndDeployFlowBuilderBotResponse {
  bot: HubtypeBot
  deployId: string
}

@Injectable()
export class BotService {
  jsonConverter: JsonConvert = new JsonConvert()

  constructor(
    protected store: Store<fromRoot.State>,
    @Inject('apiService') private apiService: HubtypeApiService,
    @Inject('convertService') private convertService: ConverterService
  ) {}

  // GET: /bots/
  getBots(): Observable<HubtypeBot[]> {
    return this.apiService
      .get(`/bots/`)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeArray(response, HubtypeBot)
        )
      )
  }

  // POST: /bots/
  createBot(botName: string): Observable<HubtypeBot> {
    const body = { name: botName }
    return this.apiService
      .post(`/bots/`, body)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeBot
          )
        )
      )
  }

  // GET: /bots/{bId}/
  getBot(botId: string): Observable<HubtypeBot> {
    return this.apiService
      .get(`/bots/${botId}/`)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeBot
          )
        )
      )
  }

  // PUT: /bots/{bId}/
  updateBot(bot: HubtypeBot): Observable<HubtypeBot> {
    return this.apiService
      .put(`/bots/${bot.id}/`, bot)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeBot
          )
        )
      )
  }

  // DELETE: /bots/{bId}/
  deleteBot(bot: HubtypeBot): Observable<boolean> {
    return this.apiService
      .delete(`/bots/${bot.id}/`)
      .pipe(map(response => true))
  }

  // GET: /bots/{bId}/updates/
  getUpdates(bot: HubtypeBot): Observable<Paginator<HubtypeBotUpdate>> {
    if (!bot) {
      return EMPTY
    }
    return this.apiService
      .get(`/bots/${bot.id}/updates/`)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeBotUpdatePaginator
          )
        )
      )
  }

  // PATCH: /bots/{bId}/
  patchIsDebug(bot: HubtypeBot, debug: boolean): Observable<HubtypeBot> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const body = { is_debug: debug }
    return this.apiService
      .patch(`/bots/${bot.id}/`, body)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeBot
          )
        )
      )
  }

  // POST: /bots/{botId}/add_integration
  addIntegration(bot: HubtypeBot) {
    const params: HttpParams = new HttpParams({
      fromObject: {
        email: bot.zendesk_email,
        domain: bot.zendesk_sitename,
        token: bot.zendesk_token,
      },
      encoder: new CustomQueryEncoderHelper(),
    })
    if (!bot) {
      return
    }
    const path = '/bots/' + bot.id + '/add_integration/'
    return this.apiService.post(path, null, params)
  }

  // POST: /bots/{botID}/unIntegrate
  unIntegrate(bot: HubtypeBot) {
    const path = '/bots/' + bot.id + '/disactivate_integration/'
    const params = {}
    return this.apiService.post(path, null, params)
  }

  getBotById(botId: string): Observable<HubtypeBot> {
    return this.store.select(fromRoot.getBotById(botId))
  }

  getAllStoredBots(): Observable<HubtypeBot[]> {
    return this.store.select(fromRoot.getBots)
  }

  getAllpaginatedBots(): Observable<HubtypeBot[]> {
    const PAGE_SIZE = 100
    return this.apiService
      .getAllPaginatedResults(PAGE_SIZE, '/bots/', 'v1')
      .pipe(
        first(),
        map(allBots => this.jsonConverter.deserializeArray(allBots, HubtypeBot))
      )
  }

  createAndDeployFlowBuilderBot(
    botName: string
  ): Observable<CreateAndDeployFlowBuilderBotResponse> {
    return this.apiService
      .postV2({
        baseUrl: environment.baseURL,
        endpoint: '/bots/deploy_flow_builder_bot_template/',
        version: 'v2',
        displayError: false,
        data: {
          name: botName,
        },
      })
      .pipe(
        first(),
        map(response => ({
          bot: this.jsonConverter.deserializeObject(response.bot, HubtypeBot),
          deployId: response.deploy_id,
        }))
      )
  }
}
