import { Component, Inject, OnInit } from '@angular/core'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'

interface NewDialogFormData {
  endUserName: string
}
@Component({
  selector: 'app-ban-user-dialog',
  templateUrl: './ban-user-dialog.component.html',
  styleUrls: ['./ban-user-dialog.component.scss'],
})
export class BanUserDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BanUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewDialogFormData
  ) {}

  ngOnInit(): void {}

  onClose(banUser = false) {
    this.dialogRef.close(banUser)
  }
}
