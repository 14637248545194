import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core'
import { Store } from '@ngrx/store'
import { HubtypeQueue } from 'models/hubtype-queue'
import {
  HubtypeUser,
  STATUS_AVAILABLE,
  STATUS_AWAY,
  STATUS_BUSY,
  STATUS_OFFLINE,
} from 'models/hubtype-user'
import { first, takeUntil } from 'rxjs'
import { AngularComponent } from 'shared/AngularComponent'
import { HtButtonDSIntent } from 'shared/base/ht-button-ds/ht-button-ds.component'
import {
  HtModalComponent,
  HtModalOptions,
} from 'shared/base/ht-modal/ht-modal.component'
import { TableConfig } from 'shared/base/ht-table/ht-table.component'
import { AnalyticsEvents } from 'src/app/constants/analytics'
import { CustomError } from 'src/app/constants/errors'
import { FeedbackService } from 'src/app/services/feedback.service'
import { QueueService } from 'src/app/services/hubtype-api/queue.service'
import { Analytics } from 'utils/analytics'
import * as fromRoot from '../../reducers'
import { FilterUserStatus } from '../filter-user-status/filter-user-status.component'
import {
  USER_AVATAR_COLUMN,
  UserListComponent,
} from '../user-list/user-list.component'
import { DEFAULT_ACTIONS } from './../base/ht-table/ht-table.component'

@Component({
  selector: 'assign-agents-to-queue-modal',
  templateUrl: './assign-agents-to-queue-modal.component.html',
  styleUrls: ['./assign-agents-to-queue-modal.component.scss'],
})
export class AssignAgentsToQueueModalComponent
  extends AngularComponent
  implements OnInit
{
  @ViewChild('agentsTable') agentsTable: UserListComponent
  @ViewChild('modal') modal: HtModalComponent
  @Input() canUnassignAgents = false

  allAgents: HubtypeUser[] = []
  disabledAgentsIds: string[] = []
  modalOptions: HtModalOptions = {
    title: { text: 'Assign agents to this queue' },
    rightBtnArray: [
      {
        show: true,
        text: 'Cancel',
        intent: HtButtonDSIntent.SECONDARY,
        click: () => this.close(),
      },
      {
        show: true,
        text: 'Assign agents',
        intent: HtButtonDSIntent.PRIMARY,
        click: () => this.assignAgents(),
        disabled: true,
      },
    ],
  }

  queue: HubtypeQueue
  searchFilter = ''
  selectedAgentsIds: string[] = []
  tableCheckboxFilter: string[] = [
    STATUS_AVAILABLE,
    STATUS_AWAY,
    STATUS_BUSY,
    STATUS_OFFLINE,
  ]
  tableConfig: TableConfig = {
    columns: [
      { ...USER_AVATAR_COLUMN, name: 'Agent' },
      {
        name: '',
        type: 'string',
        class: 'text-small text-secondary',
        computedLabel: (user: HubtypeUser) =>
          this.disabledAgentsIds.includes(user.id)
            ? 'Already assigned to this queue'
            : '',
      },
    ],
    actions: [
      {
        ...DEFAULT_ACTIONS.DELETE,
        label: 'Unassign agent',
        onClick: agent => {
          this.unassignAgent(agent)
        },
        isDeactivated: agent =>
          !this.canUnassignAgents || !this.disabledAgentsIds.includes(agent.id),
      },
    ],
    selectable: true,
    onRowSelected: (selectedRows: HubtypeUser[]) =>
      this.updateRightButtonStatus(selectedRows.length),
  }

  constructor(
    private store: Store<fromRoot.State>,
    @Inject('queueService') private queueService: QueueService,
    private feedbackService: FeedbackService
  ) {
    super()
  }

  onClose() {
    this.disabledAgentsIds = []
    this.queue = null
    this.searchFilter = ''
  }

  close() {
    this.modal.close()
  }

  ngOnInit(): void {}

  onSearch(text: string) {
    this.searchFilter = text
  }

  onStatusFilterChange(checkboxFilter: FilterUserStatus[]) {
    this.tableCheckboxFilter = checkboxFilter
      .filter(checkbox => checkbox.checked)
      .map(status => status.status)
  }

  open(queue: HubtypeQueue) {
    const selectedAgentsIds = queue.agents.map(({ id }) => id)
    this.getAllAgents()
    this.queue = queue
    this.disabledAgentsIds = [...selectedAgentsIds]
    this.selectedAgentsIds = [...selectedAgentsIds]
    this.updateRightButtonStatus(queue.agents.length)
    this.modalOptions.title.text = `Assign agents to ${queue.name}`
    this.modal.open()
  }

  private assignAgents() {
    const agents = this.agentsTable.getSelectedUsers()
    this.queueService
      .addAgents(this.queue, agents)
      .pipe(first())
      .subscribe({
        complete: () => {
          Analytics.event(AnalyticsEvents.MANAGE_QUEUE_AGENTS_MODAL_ASSIGN, {
            assignedAgents: agents.length,
          })
          this.close()
          this.feedbackService.success(
            agents.length > 1 ? 'Agents assigned' : 'Agent assigned'
          )
        },
        error: err =>
          this.feedbackService.error(
            CustomError.USER_QUEUE_ASSIGN.code,
            CustomError.USER_QUEUE_ASSIGN.message,
            err
          ),
      })
  }

  private getAllAgents() {
    this.store
      .select(fromRoot.agents)
      .pipe(first(), takeUntil(this.ngUnsubscribe))
      .subscribe(agents => {
        this.allAgents = agents
      })
  }

  private updateRightButtonStatus(queueAgentsCount: number) {
    const newAgentsLength = queueAgentsCount - this.disabledAgentsIds.length
    const rightBtn = this.modalOptions.rightBtnArray[1]
    if (newAgentsLength === 0) {
      rightBtn.text = 'Assign agents'
      rightBtn.disabled = true
    } else {
      rightBtn.text = `Assign agents (${newAgentsLength})`
      rightBtn.disabled = false
    }
  }

  private unassignAgent(agent: HubtypeUser) {
    this.queueService
      .deleteAgents(this.queue, [agent])
      .pipe(first())
      .subscribe({
        complete: () => {
          Analytics.event(AnalyticsEvents.MANAGE_QUEUE_AGENTS_MODAL_UNASSIGN)
          this.feedbackService.success('Agent unassigned')
          this.setAgentAsDeleted(agent)
        },
        error: err =>
          this.feedbackService.error(
            CustomError.USER_QUEUE_UNASSIGN.code,
            CustomError.USER_QUEUE_UNASSIGN.message,
            err
          ),
      })
  }

  private setAgentAsDeleted(agent: HubtypeUser) {
    this.disabledAgentsIds = this.disabledAgentsIds.filter(
      id => id !== agent.id
    )
    this.selectedAgentsIds = this.selectedAgentsIds.filter(
      id => id !== agent.id
    )
  }
}
